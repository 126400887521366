"use client";
import "react-toastify/dist/ReactToastify.css";
import "./global.css";
import { Nunito } from "next/font/google";
import dayjs from "dayjs";
require("dayjs/locale/es-us");
dayjs.locale("es-us");

const nunito = Nunito({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-nunito",
});

const IndexLayout = ({ children }: any) => {
  return (
    <html lang="es" className={`${nunito.variable} font-nunito`}>
      <body>
        <div>{children}</div>
      </body>
    </html>
  );
};

export default IndexLayout;
